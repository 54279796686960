import queryClient from '../../lib/reactQuery'
import { TABLE_MESSAGES } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'
import { createGeneric } from '../generic'

import type { Message } from '../../lib/supabase'
import type { MutationConfig } from '../../lib/reactQuery'

type CreateMessageArgs = {
	message: Message
}
export const createMessage = async ({ message }: CreateMessageArgs) =>
	createGeneric({ tableName: TABLE_MESSAGES, data: message })

type UseCreateMessageOptions = {
	options?: MutationConfig<typeof createMessage>
}
export const useCreateMessage = ({ options }: UseCreateMessageOptions = {}) => {
	return useMutation({
		onSuccess: (message) => {
			queryClient.setQueryData(['message', message.id], message)
		},
		...options,
		mutationFn: createMessage,
	})
}
