import React, { useState } from 'react'
import { DEFAULT_ERROR_MESSAGE } from 'utils'
import {
	Box,
	Button,
	Tab,
	List,
	Divider,
	Stack,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	AppBar,
	Autocomplete,
	TextField,
} from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import GroupMemberItem from './GroupMemberItem'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import { Group, Profile } from '../../lib/supabase'
import { useGetManyByGroupId } from 'api/profile'
import Loading from 'components/Loading'
import { object } from 'yup'
import { Validators } from 'utils/validators'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { FormInputText } from 'components/forms/elements'
import { useUpdateGroup } from 'api/group'
import { useUserContext } from 'contexts/UserContext'
import { useNavigate } from 'react-router'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import { useGroupsContext } from 'contexts/GroupsContext'
import timezones from 'utils/timezones'
import { DateTime } from 'luxon'
import InviteLink from 'components/InviteLink'
import VenueList from 'pages/Venue/VenueList'

type Props = {
	group: Group
	currentProfile?: Profile
	isAdmin: boolean
}

enum TabIndexes {
	MEMBERS = 'members',
	PROPERTIES = 'properties',
	VENUES = 'venues',
}

type FormValues = {
	groupName: string
	activityName: string
}

const GroupTabs: React.FC<Props> = (props) => {
	const { setActiveGroup } = useUserContext()
	const { showSnackbar } = useSnackbarContext()
	const { groups, removeUserFromGroup, deleteGroup } = useGroupsContext()
	const members = useGetManyByGroupId({ groupId: props.group.id })
	const [tabIndex, setTabIndex] = useState(TabIndexes.MEMBERS)
	const [showRemoveMemberConfirmation, setShowRemoveMemberConfirmation] =
		useState(false)
	const [showDeleteGroupConfirmation, setShowDeleteGroupConfirmation] =
		useState(false)
	const [showLeaveGroupConfirmation, setShowLeaveGroupConfirmation] =
		useState(false)
	const [reinviteDialog, showReinviteDialog] = useState(false)
	const [editing, setEditing] = useState(false)
	const { handleSubmit, control } = useForm<FormValues>({
		resolver: yupResolver(
			object().shape({
				groupName: Validators.STRING.required(),
				activityName: Validators.ACTIVITY_NAME.required(),
			})
		),
		defaultValues: {
			groupName: props.group.name,
			activityName: props.group.activity_name || 'Activity',
		},
	})
	const updateGroup = useUpdateGroup()
	const [toBeRemovedMember, setToBeRemovedMember] = useState<Profile>()
	const navigate = useNavigate()
	const [defaultTimezone, setDefaultTimezone] = useState(
		props.group.default_timezone
	)

	const handleOpenRemoveMemberDialog = (profile: Profile) => {
		setToBeRemovedMember(profile)
		setShowRemoveMemberConfirmation(true)
	}

	const handleRemoveMember = async () => {
		if (toBeRemovedMember && groups.isSuccess) {
			try {
				await removeUserFromGroup({
					userId: toBeRemovedMember.id,
					groupId: props.group.id,
				})

				// removing member
				setShowRemoveMemberConfirmation(false)
				setToBeRemovedMember(undefined)
				showSnackbar('Member removed', 'success')
			} catch (err) {
				if (err instanceof Error) {
					console.error(err.message)
				}
				showSnackbar(DEFAULT_ERROR_MESSAGE, 'error')
			}
		}
	}

	const handleOpenLeaveGroupDialog = (profile: Profile) => {
		setToBeRemovedMember(profile)
		setShowLeaveGroupConfirmation(true)
	}

	const handleLeaveGroup = async () => {
		if (!toBeRemovedMember || !groups.isSuccess) return

		try {
			await removeUserFromGroup({
				userId: toBeRemovedMember.id,
				groupId: props.group.id,
			})

			const newGroup = groups.data.filter(
				(group) => group.id !== props.group.id
			)?.[0]
			await setActiveGroup(newGroup ? newGroup.id : null)
			showSnackbar('Successfully left group', 'success')
			navigate('/')
		} catch (err) {
			if (err instanceof Error) {
				console.error(err.message)
			}
			showSnackbar(DEFAULT_ERROR_MESSAGE, 'error')
		}
	}

	const handleOpenDeleteGroupDialog = () => {
		setShowDeleteGroupConfirmation(true)
	}

	const handleDeleteGroup = async () => {
		if (groups.isSuccess) {
			await deleteGroup(props.group.id)

			const remainingGroups = groups.data.filter(
				(group) => group.id !== props.group.id
			)
			setActiveGroup(
				remainingGroups.length ? remainingGroups[0].id : null
			)
			navigate('/')
		}
	}

	const closeReinvite = () => {
		showReinviteDialog(false)
	}

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setTabIndex(newValue as TabIndexes)
	}

	const handleGroupUpdate = (values: FormValues) => {
		updateGroup.mutate({
			group: {
				...props.group,
				name: values.groupName,
				activity_name: values.activityName,
				default_timezone: defaultTimezone,
			},
		})
		setEditing(false)
	}

	return (
		<>
			{members.isSuccess ? (
				<Box sx={{ width: '100%' }}>
					<TabContext value={tabIndex}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<TabList
								variant='fullWidth'
								centered
								onChange={handleChange}
							>
								<Tab
									label='Members'
									value={TabIndexes.MEMBERS}
								/>
								<Tab
									label='Properties'
									disabled={!props.isAdmin}
									value={TabIndexes.PROPERTIES}
								/>
								<Tab
									label='Venues'
									disabled={!props.isAdmin}
									value={TabIndexes.VENUES}
								/>
							</TabList>
						</Box>

						{/* GROUP MEMBERS TAB PANEL */}
						<TabPanel value={TabIndexes.MEMBERS}>
							{props.isAdmin && (
								<div
									style={{
										textAlign: 'left',
										marginBottom: '1rem',
									}}
								>
									<Box
										style={{
											fontWeight: 'bold',
											fontSize: '1.0rem',
											marginBottom: '10px',
										}}
									>
										Invite Group Members by Link
									</Box>
									<Box style={{ marginBottom: '10px' }}>
										Paste the invite link into an email or
										text, send it to your friends, and when
										they click the link they&apos;ll
										automatically be added to this group.
									</Box>
									<InviteLink
										group={props.group}
										profile={props.currentProfile}
									/>
								</div>
							)}
							<div
								style={{
									fontWeight: 'bold',
									marginTop: '30px',
								}}
							>
								Current Group Members
							</div>
							<List>
								<Divider />
								{members.data.map((member) => (
									<GroupMemberItem
										key={member.id}
										member={member}
										adminId={props.group.admin}
										isAdmin={props.isAdmin}
										isCurrentUser={
											props.currentProfile?.id ===
											member.id
										}
										onRemove={handleOpenRemoveMemberDialog}
										onLeave={handleOpenLeaveGroupDialog}
									/>
								))}
							</List>
						</TabPanel>

						{/* GROUP PROPERTIES TAB PANEL */}
						<TabPanel value={TabIndexes.PROPERTIES}>
							<form
								id='group-update-form'
								onSubmit={handleSubmit(handleGroupUpdate)}
							>
								<FormInputText
									name='groupName'
									label='Group Name'
									control={control}
									variant='filled'
									disabled={!editing}
									fullWidth
									required
									sx={{ marginTop: '1rem' }}
								/>
								<FormInputText
									name='activityName'
									label='Activity Type Name'
									control={control}
									disabled={!editing}
									variant='filled'
									fullWidth
									helperText='Ex: Game, Tee Time, Book Club, etc - default is Activity'
									sx={{ margin: '1rem 0' }}
								/>
								<Autocomplete
									disablePortal
									disabled={!editing}
									options={timezones}
									onChange={(
										event: React.SyntheticEvent,
										newValue: string | null
									) => {
										setDefaultTimezone(newValue)
									}}
									value={
										props.group.default_timezone ||
										DateTime.local().zoneName
									}
									renderInput={(params) => (
										<TextField
											{...params}
											label='Timezone'
											variant='filled'
											fullWidth
										/>
									)}
								/>
							</form>

							{editing ? (
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'end',
										gap: '1.0rem',
										marginTop: '1.0rem',
									}}
								>
									<Button onClick={() => setEditing(false)}>
										Cancel
									</Button>
									<Button
										type='submit'
										form='group-update-form'
										variant='contained'
									>
										Save
									</Button>
								</div>
							) : (
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'center',
										gap: '1.0rem',
										marginTop: '1.0rem',
									}}
								>
									<Button
										fullWidth
										style={{ maxWidth: '80%' }}
										variant='contained'
										onClick={() => setEditing(true)}
									>
										Edit Group Properties
									</Button>
								</div>
							)}

							<div style={{ marginTop: '20px' }}></div>
							<AppBar
								position='fixed'
								sx={{
									top: 'auto',
									bottom: 0,
									background: '#ffffff',
									paddingBottom: '20px',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										margin: '10px 40px',
									}}
								>
									<Button
										variant='outlined'
										color='error'
										fullWidth
										style={{ maxWidth: '300px' }}
										onClick={handleOpenDeleteGroupDialog}
									>
										Delete Group
									</Button>
								</div>
							</AppBar>
						</TabPanel>
						<TabPanel value={TabIndexes.VENUES}>
							<VenueList headless={true} />
						</TabPanel>
					</TabContext>
				</Box>
			) : (
				<Loading />
			)}

			<ConfirmationDialog
				isOpen={showRemoveMemberConfirmation}
				onClose={() => setShowRemoveMemberConfirmation(false)}
				onConfirm={handleRemoveMember}
				title={'Confirm Remove Member'}
				content={
					'Do you want to permanently remove this member from this group?'
				}
				confirmButtonText='Remove Member'
			/>

			<ConfirmationDialog
				isOpen={showLeaveGroupConfirmation}
				onClose={() => setShowLeaveGroupConfirmation(false)}
				onConfirm={handleLeaveGroup}
				title={'Confirm Leave Group'}
				content={
					'Are you sure you want to leave this group? You will no longer be able to see any activities for this group.'
				}
				confirmButtonText='Leave Group'
			/>

			<ConfirmationDialog
				isOpen={showDeleteGroupConfirmation}
				onClose={() => setShowDeleteGroupConfirmation(false)}
				onConfirm={handleDeleteGroup}
				title={'Confirm Delete Group'}
				content={'Do you want to permanently delete this group?'}
				confirmButtonText='Delete Group'
			/>

			{/* REINVITE DIALOG */}
			<Dialog
				fullWidth
				open={reinviteDialog}
				onClose={closeReinvite}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>
					{'Reinvite Dialog'}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Do you want to reinvite this user?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Stack direction='row' spacing={1}>
						<Button onClick={closeReinvite}>Cancel</Button>
						<Button onClick={closeReinvite} variant='outlined'>
							Cancel Invitation
						</Button>
						<Button
							onClick={closeReinvite}
							variant='contained'
							autoFocus
						>
							Re-send Invitation
						</Button>
					</Stack>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default GroupTabs
