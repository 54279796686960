import React, { useEffect, useState } from 'react'
import {
	Box,
	Divider,
	List,
	Typography,
	Paper,
	Avatar,
	AvatarGroup,
	IconButton,
} from '@mui/material'
import { useGetManyByUserId } from 'api/activity/getManyByUserId'
import { useGetManyByGroupId as getMessagesByGroupId } from 'api/messages/getManyByGroupId'
import { getManyByGroupId as getGroupMembers } from 'api/profile'
import Loading from 'components/Loading'
import { useUserContext } from 'contexts/UserContext'
import MessageBox from './MessageBox'
import { DateTime } from 'luxon'
import { Activity, Message } from 'lib/supabase'
import { useNavigate } from 'react-router'
import type { Profile } from 'lib/supabase'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

const Messages: React.FC = () => {
	const navigate = useNavigate()
	const { profile, activeGroup } = useUserContext()
	const [members, setMembers] = useState<Profile[]>([])

	useEffect(() => {
		if (activeGroup) {
			getGroupMembers({ groupId: activeGroup.id }).then((response) => {
				setMembers(response)
			})
		}
	}, [activeGroup])

	const handleNavigateToGroupChat = () => {
		navigate(`/chat/${profile?.active_group}`)
	}
	const activities = useGetManyByUserId({
		userId: profile?.id,
		groupId: activeGroup?.id,
		options: { enabled: !!profile },
	})
	const [filteredActivities, setFilteredActivities] =
		React.useState<Activity[]>()

	useEffect(() => {
		if (activities.isSuccess) {
			setFilteredActivities(
				activities.data.filter(
					(activity) =>
						activity.active &&
						DateTime.fromISO(activity.datetime) >
							DateTime.now().plus({ hours: 1 })
				)
			)
		}
	}, [activities.data, activities.isSuccess])

	const { data: messages } = getMessagesByGroupId({
		groupId: profile?.active_group || '',
		options: { enabled: !!profile?.active_group },
	})

	const getLatestMessageDateForActivity = (activityId: string) => {
		if (activityId && messages) {
			const latestDate = messages.filter(
				(message: Message) => message.activity_id === activityId
			)[0]?.created_at
			return latestDate
		} else {
			return ''
		}
	}

	const getLatestMessageDateforGroup = () => {
		if (messages) {
			const latestDate = messages.filter(
				(message: Message) => message.activity_id === null
			)[0]?.created_at
			return latestDate
		} else {
			return ''
		}
	}

	function stringToColor(string: string) {
		let hash = 0
		let i

		/* eslint-disable no-bitwise */
		for (i = 0; i < string.length; i += 1) {
			hash = string.charCodeAt(i) + ((hash << 5) - hash)
		}

		let color = '#'

		for (i = 0; i < 3; i += 1) {
			const value = (hash >> (i * 8)) & 0xff
			color += `00${value.toString(16)}`.slice(-2)
		}
		/* eslint-enable no-bitwise */

		return color
	}

	function stringAvatar(name: string) {
		return {
			sx: {
				bgcolor: stringToColor(name),
			},
			children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
		}
	}

	const latestGroupMessage = getLatestMessageDateforGroup()
	console.log('latestGroupMessage ' + latestGroupMessage)

	return (
		<Box style={{ paddingBottom: '60px' }}>
			{activities.isSuccess ? (
				<>
					<Paper
						elevation={3}
						style={{ margin: '10px', padding: '10px' }}
					>
						<div onClick={handleNavigateToGroupChat}>
							<Typography
								fontWeight={1000}
								fontSize={'1.0rem'}
								textTransform={'uppercase'}
							>
								Group Conversation
							</Typography>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
								}}
							>
								<AvatarGroup
									max={10}
									spacing={12}
									style={{
										display: 'flex',
										justifyContent: 'left',
										paddingTop: '10px',
									}}
								>
									{members.map((member) => (
										<Avatar
											key={member.full_name}
											style={{
												width: 36,
												height: 36,
												fontSize: '1.0rem',
												textTransform: 'uppercase',
											}}
											{...stringAvatar(member.full_name)}
										/>
									))}
								</AvatarGroup>
								<IconButton size='large'>
									<KeyboardArrowRightIcon />
								</IconButton>
							</div>
							<Typography
								fontSize={'0.7rem'}
								color={'#666666'}
								fontStyle={'italic'}
								padding={'5px 0px 0px 10px'}
							>
								{latestGroupMessage === undefined
									? 'No messages yet'
									: 'Latest message posted ' +
									  DateTime.fromISO(
											latestGroupMessage
									  ).toRelative()}
							</Typography>
						</div>
						<Typography
							marginTop={'20px'}
							fontWeight={1000}
							fontSize={'1.0rem'}
							textTransform={'uppercase'}
						>
							Activity Conversations
						</Typography>
						<List dense>
							<Divider />
							{filteredActivities &&
							filteredActivities.length > 0 ? (
								filteredActivities.map((activity) => {
									const latestMessage =
										getLatestMessageDateForActivity(
											activity.id
										)

									return (
										<MessageBox
											key={activity.id}
											activity={activity}
											latestDate={latestMessage}
										/>
									)
								})
							) : (
								<Typography
									textAlign='center'
									marginTop={'2.0rem'}
									marginX={'2.0rem'}
								>
									No activity chats to display.
								</Typography>
							)}
						</List>
					</Paper>
				</>
			) : (
				<Loading />
			)}
		</Box>
	)
}

export default Messages
