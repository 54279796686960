import React from 'react'
import { Outlet, useNavigate } from 'react-router'
import TopNav from './TopNav'
import AppHeader from 'components/AppHeader'
import SettingsDrawer from 'components/SettingsDrawer'
import { useUserContext } from 'contexts/UserContext'
import { useDashboardInfo } from 'contexts/DashboardInfoContext'
import { Button } from '@mui/material'
import Welcome from './Welcome/Welcome'
import Loading from 'components/Loading'
import NoGroups from './NoGroups'

export type MainOutletContext = {
	setShowTopNav: (show: boolean) => void
	setSettingsDisabled: (disabled: boolean) => void
}

const MainLayout: React.FC = () => {
	const [showTopNav, setShowTopNav] = React.useState(true)
	const [settingsDisabled, setSettingsDisabled] = React.useState(false)
	const { profile, activeGroup } = useUserContext()
	const { showPollsBadge, showMessagesBadge } = useDashboardInfo()
	const navigate = useNavigate()
	const headerRef = React.useRef<HTMLDivElement>()

	return (
		<>
			{profile ? (
				<>
					<>
						<AppHeader
							ref={headerRef}
							rightComponent={
								<SettingsDrawer
									disabled={settingsDisabled}
									anchor='right'
								/>
							}
							centerComponent={
								activeGroup && (
									<Button
										style={{
											color: '#ffffff',
											fontSize: '1.2rem',
											textTransform: 'none',
										}}
										onClick={() =>
											navigate(`/group/${activeGroup.id}`)
										}
										data-intro='Tap the group name to view details about the group.'
									>
										{activeGroup.name}
									</Button>
								)
							}
						/>
						{profile.viewed_welcome ? (
							profile.active_group == null ? (
								<>
									<NoGroups />
								</>
							) : (
								<>
									{showTopNav && (
										<TopNav
											pollBadge={showPollsBadge}
											messageBadge={showMessagesBadge}
										/>
									)}

									<Outlet
										context={{
											setShowTopNav,
											setSettingsDisabled,
										}}
									/>
								</>
							)
						) : (
							<Welcome
								headerRef={headerRef}
								profile={profile}
								setShowTopNav={setShowTopNav}
								setSettingsDisabled={setSettingsDisabled}
							/>
						)}
					</>
				</>
			) : (
				<Loading />
			)}
		</>
	)
}

export default MainLayout
