import { useEffect } from 'react'
import {
	getInfiniteByGroupId,
	getInfiniteQueryKeyByGroupId,
	useCreateMessage,
} from 'api/messages'
import queryClient from 'lib/reactQuery'
import supabase, { type Message, TABLE_MESSAGES } from 'lib/supabase'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import { DEFAULT_ERROR_MESSAGE } from 'utils'
import { useUserContext } from 'contexts/UserContext'

type UseMessagesByGroupArgs = {
	groupId: string
}

const useMessagesByGroup = ({ groupId }: UseMessagesByGroupArgs) => {
	const { profile } = useUserContext()
	const { showSnackbar } = useSnackbarContext()
	const createMessage = useCreateMessage()
	const messages = getInfiniteByGroupId({
		groupId: groupId,
	})

	const addMessageToFrontOfCache = (message: Message) => {
		// lazily add the new message to the front of the cache
		// when the cache is next queried, the new message will be included
		// and the structure will be reset
		queryClient.setQueryData<typeof messages.data>(
			getInfiniteQueryKeyByGroupId(groupId),
			(oldData) => {
				const newPage = oldData ? oldData.pages[0] : []
				newPage.unshift(message)
				return {
					pageParams: oldData ? oldData.pageParams : [0],
					pages: oldData
						? [newPage, ...oldData.pages.slice(1)]
						: [newPage],
				}
			}
		)
	}

	// listen for new messages in this activity
	useEffect(() => {
		const sub = supabase
			.channel('messages-db-changes')
			.on(
				'postgres_changes',
				{
					event: 'INSERT',
					schema: 'public',
					table: TABLE_MESSAGES,
					filter: `group_id=eq.${groupId}`,
				},
				async (payload) => {
					const message = payload.new as Message
					if (message.sender !== profile?.id)
						addMessageToFrontOfCache(message)
				}
			)
			.subscribe()

		return () => {
			sub.unsubscribe()
		}
	}, [supabase, messages.data, groupId])

	const sendMessage = async (message: Message) => {
		// save previous cache to restore if sending fails
		const prevMessages = queryClient.getQueryData(
			getInfiniteQueryKeyByGroupId(groupId)
		)
		try {
			// update the database behind the scenes
			message.activity_id = null // group chat messages don't have an activity_id
			await createMessage.mutateAsync({ message })

			queryClient.invalidateQueries({
				queryKey: getInfiniteQueryKeyByGroupId(groupId),
			})
		} catch (err) {
			if (err instanceof Error) {
				console.error(err.message)
			}
			// revert if something goes wrong
			queryClient.setQueryData(
				getInfiniteQueryKeyByGroupId(groupId),
				prevMessages
			)
			showSnackbar(DEFAULT_ERROR_MESSAGE, 'error')
		}
	}

	return {
		...messages,
		sendMessage,
	}
}

export default useMessagesByGroup
